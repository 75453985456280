import React, {useState} from 'react';
import {InitialQuestions} from './InitialQuestions';
import {homePageInitialState, useHomePageContext} from '../../context/HomePageContext';
import {buildChatData, CommsCheck} from '../commsCheck/CommsCheck';
import {
  DecommissionReasonContext,
  DecommissionReasonType,
  DecommissionRequestContext,
  DecommissionRequestType,
  DecommissionResultContext,
  DecommissionResultType,
  DecommissionScreenContext,
  DecommissionScreenType,
  useDecommissionInputsContext,
} from '../../context/DecommissionContext';
import commsCheckInitialData, {CommsCheckContext} from '../../context/CommsCheckContext';
import {CommsCheckResult, XrinvData} from '../../types/comms-check';
import {ButtonContainer} from '../common/buttonContainer/ButtonContainer';
import {Button, ButtonTheme} from '../common/button/Button';
import {EmptySpaceForFixedButtons} from '../common/emptySpaceForFixedButtons/EmptySpaceForFixedButtons';
import {signOut} from 'aws-amplify/auth';
import {startChat} from '../chat/chat-jq';
import {getAuthTokenOrLogOut} from '../util';
import SelectDecommissionReason from './SelectDecommissionReason';
import DecommissionSummary from './DecommissionSummary';
import {DecommissionLoading} from './DecommissionLoading';
import {DecommissionResult} from './DecommissionResult';

export default function DecommissionAMeter() {
  const { homePageState, setHomePageState } = useHomePageContext();
  const { decommissionInputs, setDecommissionInputs } = useDecommissionInputsContext();
  const [commsCheckResultContext, setCommsCheckResultContext] = useState<CommsCheckResult>(commsCheckInitialData);

  const [screen, setScreen] = useState<DecommissionScreenType>(DecommissionScreenType.INITIAL);
  const [reason, setReason] = useState<DecommissionReasonType>();
  const [decommissionResult, setDecommissionResult] = useState<DecommissionResultType>({
    status: -1,
    deviceType: '',
    deviceId: '',
  });
  const [decommissionRequest, setDecommissionRequest] = useState<DecommissionRequestType>({
    deviceType: '',
    deviceId: '',
    mpxn: '',
    chfDeviceId: '',
  });

  async function startCommsCheckHandler() {
    setScreen(DecommissionScreenType.COMMS_CHECK);
    setHomePageState({
      ...homePageState,
      automationInProgress: true
    })
  }

  function goBackHandler() {
    setHomePageState({
      ...homePageState,
      automationInProgress: false
    })
    setCommsCheckResultContext(commsCheckInitialData);
    setScreen(DecommissionScreenType.INITIAL);
    setDecommissionInputs({ ...decommissionInputs, onsiteRadioAnswer: '', meterTypeRadioAnswer: '' });
  }

  async function startChatHandler() {
    const chatData = buildChatData(commsCheckResultContext, decommissionInputs.mpxn, decommissionInputs.msn);
    const userDetails = await getAuthTokenOrLogOut();
    startChat(chatData, userDetails.userEmail, userDetails.token, decommissionInputs.mpxn);
  }

  function askAboutSomethingElseHandler() {
    console.log('ask about something else pressed');
    setDecommissionResult({
      status: -1,
      deviceType: '',
      deviceId: '',
    })
    setScreen(DecommissionScreenType.INITIAL);
    setHomePageState({
      ...homePageInitialState,
      brandSelected: homePageState.brandSelected,
      automationInProgress: false,
    });
  }

  function continueToDecommission() {
    setScreen(DecommissionScreenType.SELECT_DECOMMISSION_REASON);
  }

  function renderActionButtons(data: CommsCheckResult) {
    if (data.xrinv.state && data.xritm.state && data.xrdlg.state) {
      if (data.xrinv.state === 'SUCCESS' && data.xritm.state === 'SUCCESS' && data.xrdlg.state === 'SUCCESS') {
        return <>
          <ButtonContainer>
            <Button buttonId={'continue-decommission'} buttonText={'Continue to decommission'} buttonTheme={ButtonTheme.PRIMARY}
                    onClickHandler={continueToDecommission}/>
            {data.xrdlg.data.length ?
              <Button buttonId={'start-chat-decommission'} buttonText={'Start Chat'} buttonTheme={ButtonTheme.PRIMARY}
                      onClickHandler={startChatHandler}/> : null
            }
            <Button buttonId={'go-back-decommission'} buttonText={'Go Back'} buttonTheme={ButtonTheme.SECONDARY}
                    onClickHandler={goBackHandler}/>
          </ButtonContainer>
          <EmptySpaceForFixedButtons numberOfButtons={data.xrdlg.data.length ? 3 : 2}/>
        </>
      } else {
        return <>
          <ButtonContainer>
            <Button buttonId={'continue-decommission'} buttonText={'Continue to decommission'} buttonTheme={ButtonTheme.PRIMARY}
                    onClickHandler={continueToDecommission} isButtonEnabled={data.xrinv.state === 'SUCCESS'}/>
            <Button buttonId={'start-chat-decommission'} buttonText={'Start Chat'} buttonTheme={ButtonTheme.PRIMARY}
                    onClickHandler={startChatHandler}/>
            <Button buttonId={'go-back-decommission'} buttonText={'Go Back'} buttonTheme={ButtonTheme.SECONDARY}
                    onClickHandler={goBackHandler}/>
          </ButtonContainer>
          <EmptySpaceForFixedButtons numberOfButtons={3}/>
        </>
      }
    } else {
      if (homePageState.automationInProgress) {
        return <>
          <ButtonContainer>
            <Button buttonId="go-back-decommission" buttonText="Go Back" buttonTheme={ButtonTheme.PRIMARY} isButtonEnabled={true} onClickHandler={goBackHandler}/>
            <Button buttonId="log-out-decommission" buttonText="Log Out" buttonTheme={ButtonTheme.SECONDARY} onClickHandler={signOut}/>
          </ButtonContainer>
          <EmptySpaceForFixedButtons numberOfButtons={2}/>
        </>
      } else {
        return <></>;
      }
    }
  }

  function renderDecommissionSummary() {
    const device: XrinvData = commsCheckResultContext.xrinv.data.find(xrinvData => xrinvData.mpxn === decommissionInputs.mpxn) ?? {
      deviceType: '', deviceStatus: '', mpxn: '', deviceId: '', smetsDeviceType: '', lastCommunicatedTime: '',
    };

    const chfId = commsCheckResultContext.xrinv.data.find(xrinvData => xrinvData.deviceType === 'CHF')?.deviceId ?? '';

    return <DecommissionSummary
      deviceType={device.deviceType}
      smetsDeviceType={device.smetsDeviceType}
      deviceId={device.deviceId}
      chfDeviceId={chfId}
      reason={reason ?? ''}
      mpxn={decommissionInputs.mpxn}/>
  }

  return (
    <DecommissionScreenContext.Provider value={{ screen, setScreen }}>
      {
        screen === DecommissionScreenType.INITIAL && <InitialQuestions primaryButtonHandler={startCommsCheckHandler}/>
      }

      <DecommissionRequestContext.Provider value={{ decommissionRequest, setDecommissionRequest }}>
        <CommsCheckContext.Provider value={{ commsCheckResultContext, setCommsCheckResultContext }}>
          {
            screen === DecommissionScreenType.COMMS_CHECK &&
            <>
              <CommsCheck
                mpxn={decommissionInputs.mpxn}
                msn={decommissionInputs.msn}
              />
              {
                renderActionButtons(commsCheckResultContext)
              }
            </>
          }

          <DecommissionReasonContext.Provider value={{ decommissionReason: reason, setDecommissionReason: setReason }}>
            {
              screen === DecommissionScreenType.SELECT_DECOMMISSION_REASON && <SelectDecommissionReason/>
            }
            {
              screen === DecommissionScreenType.DECOMMISSION_SUMMARY && renderDecommissionSummary()
            }
          </DecommissionReasonContext.Provider>
        </CommsCheckContext.Provider>

        <DecommissionResultContext.Provider value={{ decommissionResult, setDecommissionResult }}>
          {
            screen === DecommissionScreenType.DECOMMISSION_LOADING && <DecommissionLoading/>
          }
          {
            screen === DecommissionScreenType.DECOMMISSION_RESULT && <DecommissionResult startChatHandler={startChatHandler} askAboutSomethingElseHandler={askAboutSomethingElseHandler} finishHandler={goBackHandler}/>
          }
        </DecommissionResultContext.Provider>
      </DecommissionRequestContext.Provider>
    </DecommissionScreenContext.Provider>
  );
}
